import Octopus from "../Octopus";
import { AxiosError } from "axios";

interface PostLogsParams {
  request_id?: string;
  type: string;
  req_path: string;
  msg: AxiosError;
}

type UnknownData = { [key: string]: unknown };

interface PostLogsErrorParams extends UnknownData {
  req_path: string;
  msg: string;
}

const sanitizeConfig = (msg: AxiosError) => {
  if (!msg?.config) return;

  const { config } = msg;

  if (config.data) {
    config.data = undefined;
  }

  if (config.headers) {
    config.headers.Authorization = undefined;
    config.headers.OTP = undefined;
  }
};

export async function postLogs({ msg, ...props }: PostLogsParams) {
  return await Octopus.request("post", `/ex/logs/debug`, {
    ...props,
    msg: JSON.stringify(sanitizeConfig(msg)),
    level: "ERROR",
    source: "expert",
  });
}

export async function postErrorLogs({ ...props }: PostLogsErrorParams) {
  return await Octopus.request("post", `/ex/logs/debug`, {
    ...props,
    level: "ERROR",
    source: "expert",
  });
}
