import Octopus from "../Octopus";
import { useQuery } from "react-query";
import {
  MedicalFilesResult,
  MedicalFileInfos,
  RequestData,
  RequestDataResult,
  ApplicantInfoResult,
  ApplicantData,
  PatientData,
  PatientInfoResult,
  RequestNatureResult,
  RequestDataShort,
} from "./request-types";
import { postLogs } from "api/logs";

export async function ImportRequestInfo(
  request_id: string,
  lang?: string,
): Promise<RequestData> {
  const r: RequestDataResult = await Octopus.request(
    "get",
    "/ex/requests/data",
    undefined,
    {
      request_id,
      lang,
    },
  );
  if (r.Error) {
    throw new Error(r.Error);
  }
  const {
    result: [data],
  } = r;
  return data || {};
}

export const useRequestInfos = (request_id: string, lang?: string) =>
  useQuery<RequestData, Error>(
    [`requestInfos-${request_id}`, request_id, lang],
    () => ImportRequestInfo(request_id, lang),
    {
      staleTime: 600000,
      cacheTime: 600000 /* 10mins */,
      enabled: !!lang && !!request_id,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

export async function ImportPatientInfo(
  request_id: string,
): Promise<PatientData> {
  const { result }: PatientInfoResult = await Octopus.request(
    "get",
    "/ex/requests/identity",
    undefined,
    {
      request_id,
    },
  );
  return result || {};
}

export const usePatientInfos = (request_id: string) =>
  useQuery<PatientData, Error>(
    [`patientInfos-${request_id}`, request_id],
    () => ImportPatientInfo(request_id),
    {
      staleTime: 600000,
      cacheTime: 600000,
      enabled: !!request_id,
      retry: false /* 10mins */,
    },
  );

export async function ImportApplicantInfo(
  request_id: string,
): Promise<ApplicantData> {
  const {
    result: [data],
  }: ApplicantInfoResult = await Octopus.request(
    "get",
    "/ex/requests/applicant",
    undefined,
    {
      request_id,
    },
  );
  return data || {};
}

export const useApplicantInfos = (request_id: string) =>
  useQuery<ApplicantData, Error>(
    [`applicantInfos-${request_id}`, request_id],
    () => ImportApplicantInfo(request_id),
    { staleTime: 600000, cacheTime: 600000, retry: false /* 10mins */ },
  );

// Description Section
export async function ImportRequestDescription(
  request_id: string,
  lang?: string,
): Promise<string> {
  const {
    result: { nature },
  }: RequestNatureResult = await Octopus.request(
    "get",
    "/ex/requests/nature",
    undefined,
    {
      request_id,
      lang,
    },
  );

  return nature || "";
}

export const useRequestDescription = (request_id: string, lang?: string) =>
  useQuery<string, Error>(
    [`requestDescription-${request_id}`, request_id, lang],
    () => ImportRequestDescription(request_id, lang),
    {
      staleTime: 600000,
      cacheTime: 600000,
      enabled: !!request_id,
      retry: false /* 10mins */,
    },
  );

export interface QuestionnaireTemplate {
  question: {
    description: string;
    group_name: string;
    id: string;
    level: string;
    picture: string;
    question: string;
    question_id: string;
    range_end: string;
    range_start: string;
    required: string;
    type: string;
    position_number: number;
  }[];
  response: {
    id: string;
    name: string;
    next_id: string;
    next_question_id: string;
    reponse_id: string;
  }[];
}

export async function ImportRequestMedicalQuestionnaireTemplate(
  request_id: string,
  lang?: string,
): Promise<QuestionnaireTemplate> {
  return await Octopus.request("get", "/ex/questionnaire/template", undefined, {
    request_id,
    lang,
  }).then(response => (response && response.result ? response.result : null));
}

export const useRequestMedicalQuestionnaireTemplate = (
  request_id: string,
  lang?: string,
) =>
  useQuery(
    [
      `requestMedicalQuestionnaireTemplate-${request_id}-${lang}`,
      request_id,
      lang,
    ],
    () => ImportRequestMedicalQuestionnaireTemplate(request_id, lang),
    { staleTime: 600000, cacheTime: 600000, retry: false /* 10mins */ },
  );

export interface Questionnaire {
  id?: string;
  parent_response_id: string;
  position_number: number;
  question: string;
  response: string;
  response_id?: number[];
  range_start?: string;
  range_end?: string;
  picture?: string;
  responses_list?: {
    id: number;
    name: string;
  }[];
}

export async function ImportRequestMedicalQuestionnaire(
  request_id: string,
  lang?: string,
): Promise<Record<string, Questionnaire>> {
  return await Octopus.request("get", "/ex/questionnaire", undefined, {
    request_id,
    lang,
  })
    .then(response => (response && response.result ? response.result : {}))
    .catch(err => {
      postLogs({
        type: "old-logs",
        msg: err,
        req_path: window.location.pathname,
      });
      return err;
    });
}
interface QuestionnaireQuestion {
  description: string;
  group_name: string;
  id: string;
  level: string;
  picture: string;
  question: string;
  question_id: string;
  range_end: string;
  range_start: string;
  required: string;
  type: string;
}

const ImportRequestMedicalQuestionnaireQuestion = async (
  request_id: string,
  type?: string,
  lang?: string,
): Promise<QuestionnaireQuestion[]> => {
  return await Octopus.request("GET", "/ex/questionnaire/question", undefined, {
    request_id,
    lang,
    type,
  }).then(response => (response && response.result ? response.result : {}));
};
export const useRequestMedicalQuestionnaireQuestion = (
  request_id: string,
  type = "patient",
  lang?: string,
) =>
  useQuery(
    [
      `requestMedicalQuestionnaireQuestion -${request_id}-${lang}`,
      request_id,
      type,
      lang,
    ],
    () => ImportRequestMedicalQuestionnaireQuestion(request_id, type, lang),
    { staleTime: 600000, cacheTime: 600000, retry: false /* 10mins */ },
  );

export const useRequestMedicalQuestionnaire = (
  request_id: string,
  lang?: string,
) =>
  useQuery(
    [`requestMedicalQuestionnaire-${request_id}-${lang}`, request_id, lang],
    () => ImportRequestMedicalQuestionnaire(request_id, lang),
    { staleTime: 600000, cacheTime: 600000, retry: false /* 10mins */ },
  );

export async function ImportRequestMedicalFiles(
  request_id: string,
  order?: string,
  lang?: string,
): Promise<Array<MedicalFileInfos>> {
  const response: MedicalFilesResult = await Octopus.request(
    "get",
    "/ex/requests/medical-file-uploaded",
    undefined,
    { request_id, order, lang },
  );

  return response && response.sql ? response.sql : [];
}

export const useRequestMedicalFiles = (
  request_id: string,
  order?: string,
  lang?: string,
) =>
  useQuery(
    [
      `requestMedicalFiles-${request_id}-${order}-${lang}`,
      request_id,
      order,
      lang,
    ],
    () => ImportRequestMedicalFiles(request_id, order, lang),
    { staleTime: 600000, cacheTime: 600000, retry: false /* 10mins */ },
  );

export async function PutMedicalFileAsRead(
  request_id: string,
  file_id: string,
): Promise<BooleanResult> {
  const response = await Octopus.request(
    "put",
    "/ex/requests/medical-file-uploaded/set-as-read",
    undefined,
    { request_id, file_id },
  );
  return response;
}

interface IConfirmRequest {
  Code: number;
}

export async function ConfirmRequest(
  request_id: string,
  verdict: string,
): Promise<IConfirmRequest> {
  function verdictToString(verdict: string): string | undefined {
    switch (verdict) {
      case "accepted":
        return "accept";
      case "suspended":
        return "ask-information";
      case "refused":
        return "refuse";
    }
  }

  if (verdict === "suspended") {
    await Octopus.request("put", `/ex/requests/experts/accept`, undefined, {
      request_id,
    });
  }

  return await Octopus.request(
    "put",
    `/ex/requests/experts/${verdictToString(verdict)}`,
    undefined,
    { request_id },
  );
}

export async function GetComment(request_id: string): Promise<string> {
  return await Octopus.request("get", `/ex/requests/note`, undefined, {
    request_id,
  }).then(result =>
    result.result && result.result.note ? result.result.note : "",
  );
}

export async function SaveComment(request_id: string, text: string) {
  return await Octopus.request(
    "post",
    `/ex/requests/note`,
    {
      note: text,
    },
    {
      request_id,
    },
  );
}

export async function createQuotation(
  request_id: string,
  quotation_type: string,
  currency: string,
  signature: string,
  amount?: number,
) {
  return await Octopus.request(
    "post",
    `/ex/requests/quotation-create`,
    {
      quotation_type,
      amount,
      currency,
      signature,
    },
    {
      request_id,
    },
  );
}

export const patchQuotation = (
  request_id: string,
  quotation_id: string,
  signature: string,
) => {
  return Octopus.request(
    "patch",
    "/ex/requests/quotation-edit",
    { signature },
    {
      request_id,
      quotation_id,
    },
  )
    .then(response => response.result)
    .catch(err => err);
};

export async function linkQuotationToDocument(
  request_id: string,
  quotation_id: string,
  request_document_id: number,
) {
  return await Octopus.request(
    "put",
    `/ex/requests/quotations/set-document-id`,
    {
      quotation_id,
      request_document_id,
    },
    {
      request_id,
    },
  );
}

export async function saveSuspendRequestMessageToApplicant(
  request_id: string,
  text: string,
  applicant_id: number,
) {
  return await Octopus.request(
    "post",
    `/ex/messages`,
    {
      message: text,
      type: "expert",
      recipient_table: "applicant",
      recipient_id: applicant_id,
    },
    { request_id },
  );
}

export async function saveRefuseRequestReason(
  request_id: string,
  reason: string,
) {
  return await Octopus.request(
    "put",
    "/ex/requests/experts/refuse",
    { reason },
    { request_id },
  );
}

export async function sendOpinion(request_id: string) {
  try {
    return await Octopus.request(
      "post",
      `/ex/requests/opinions/send`,
      undefined,
      { request_id },
    );
  } catch (error) {
    postLogs({
      request_id,
      type: "old-logs",
      msg: error,
      req_path: window.location.pathname,
    });
    return error;
  }
}

async function ImportRequests(): Promise<{
  result: Array<RequestDataShort>;
}> {
  return await Octopus.request("get", "/ex/requests/list-expert").then(
    response => (response && response.result ? response : { result: [] }),
  );
}

export const useRequestsList = (lang: string) =>
  useQuery(`requests-${lang}`, ImportRequests, {
    refetchOnWindowFocus: false,
    enabled: typeof lang !== "undefined" && typeof lang === "string",
    keepPreviousData: false,
    retry: false,
    cacheTime: 500,
    staleTime: 500,
  });

export async function generateOsimis(
  request_id: string,
  request_key: string,
  medical_file_id: string,
  medical_file_document_id: string,
  document_position: string,
) {
  return await Octopus.request(
    "post",
    "/ex/requests/osimis",
    {
      request_key,
      medical_file_id,
      medical_file_document_id,
      document_position,
    },
    { request_id },
  )
    .then(response => response)
    .catch(e => e);
}
